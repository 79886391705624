class ImageTextColumnLandingPageWidget implements LandingPageWidget {
    centerText = false;
    columns:ImageTextColumn[]=[];
    getClassName(): string {
        return "image-and-text-column";
    }
    getName(): string {
        return "Image & Text Column";
    }
    render(container: HTMLDivElement, rowIndex: number, firstLoad: boolean, prefixClassName: string): void {
        // TODO: render the form fields, not the widget
    }
    loadData(data:any):void{
        this.centerText=data.centerText;
        for(let i=0;i<data.columns.length;i++) {
            let column=data.columns[i];
            let imageTextColumn=new ImageTextColumn();
            for(let n in column) {
                if (imageTextColumn.hasOwnProperty(n)) {
                    imageTextColumn[n] = column[n];
                }
            }
            this.columns.push(imageTextColumn);
        }
    }
}